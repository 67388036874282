import React from 'react';
import './App.css';
import Picture from './Picture/Picture';
import Sidebar from './Sidebar/Sidebar';
import Navbar from './Navbar/Navbar';



function App() {
  


  return (
   
    <div className="App">
      <Navbar/>
      <Picture/>
     <Sidebar/>
    
    </div>
  );
}

export default App;
