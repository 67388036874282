import React from 'react';
import style from './Women.module.css'
import pic from '../Picture/1.jpg'
import pic2 from '../Picture/4.jpg'
import pic3 from '../Picture/3.jpg'
import pic4 from '../Picture/2.jpg'
import pic5 from '../Picture/5.jpg'
import pic6 from '../Picture/3.jpg'

export default class Women extends React.Component{

render() {
    return (
        <div className={style.women}>
            <figure>
            <img src={pic}/>
            </figure>
            <figure>
            <img src={pic2}/>
            </figure>
            <figure>
            <img src={pic3}/>
            </figure>
            <figure>
            <img src={pic4}/>
            </figure>
            <figure>
            <img src={pic5}/>
            </figure>
            <figure>
            <img src={pic6}/>
            </figure>
        </div>
    )
}


}