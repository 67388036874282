import React from 'react';
import style from './Men.module.css'
import pic from '../Picture/1.jpg'
import pic2 from '../Picture/4.jpg'
import pic3 from '../Picture/3.jpg'


export default class Men extends React.Component{

render() {
    return (
        <div className={style.men}>
          <div className={style.box}>
        <h2>Tech wear Lab</h2>
        <hr/>
        <p>20026 Wear Collection</p>


       </div>

        <div className={style.box2}>
            <figure>
            <img src={pic}/>
            </figure>
            <figure>
            <img src={pic2}/>
            </figure>
            <figure>
            <img src={pic3}/>
            </figure>
           
        </div>

        </div>
    )
}


}