import React from 'react';
import style from './Sidebar.module.css';
import fb from './1.svg'
import fm from './2.svg'
import fr from './3.svg'
export default class Sidebar extends React.Component{

render() {
    return (
        <div className={style.sidebar}>
            <ul>
                <li><a href=""><img src={fb}/></a></li>
                <li><a href=""><img src={fm}/></a></li>
                <li><a href=""><img src={fr}/></a></li>
            </ul>
            
        </div>
    )
}

}