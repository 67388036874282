import React from 'react';
import style from './Picture.module.css';
import pic from './2.jpg';




const Picture = () => {

   

    return (
        <div>
             <div className={style.picture}>
           <img src={pic}/>
        </div>
        </div>
    )
}

export default Picture;
