import React from 'react';
import style from './Main.module.css';




export default class Main extends React.Component{

render() {
    return (
        <div className={style.main}>
           
       <div className={style.box}>
        <h2>Tech wear Lab</h2>
        <hr/>
        <p>20026 Wear Collection</p>


       </div>

        <div className={style.box2}>

        <h1>ACRONYMO</h1>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta aperiam sed, eaque incidunt nemo amet aliquam similique qui? Quibusdam, unde itaque! Eum necessitatibus placeat quis alias, provident eligendi voluptatum magni.</p>

        </div>

        </div>
    )
}

}