import React,{useState} from 'react';



export const CartContex =React.createContext()

export const CartProvider=(props)=>{

    const[cart,setCart]=useState()

return(
  <CartContex.Provider value={[cart,setCart]}>

            
            {props.children}

  </CartContex.Provider>
)


}