import React from 'react'
import style from './Navbar.module.css';
import {BrowserRouter as Router,Link,Switch,Route} from 'react-router-dom'
import Main from '../Main/Main';
import Men from '../Men/Men';
import Women from '../Women/Women';


const Navbar = () => {




  return (
 
            <div className={style.navbar}>
         
                 <Router>
                   

<nav>

<ul className={style.list}>
  <li><Link to="/">Acrinm</Link></li>
  <li ><Link to="/Men">Men</Link></li>
  <li><Link to="/Women">Women</Link></li>
</ul>

</nav>

<Switch>

<Route exact path="/"  component={Main}/>
<Route path="/Men"  component={Men}/>
<Route path="/Women"  component={Women}/>





</Switch>


  </Router>







    </div>
  )
}

export default Navbar;
